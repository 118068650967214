import { useEffect } from 'react';
import Checkbox from '@/Components/Form/Checkbox';
import GuestLayout from '@/Layouts/GuestLayout';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/Form/TextInput';
import FormControl from '@/Components/Form/FormControl';
import Label from '@/Components/Form/Label';
import { Head, Link, useForm } from '@inertiajs/react';

export default function Login({ status, canResetPassword }) {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: '',
		password: '',
		remember: ''
	});

	useEffect(() => {
		return () => {
			reset('password');
		};
	}, []);

	const onHandleChange = (event) => {
		setData(
			event.target.name,
			event.target.type === 'checkbox' ? event.target.checked : event.target.value
		);
	};

	const submit = (e) => {
		e.preventDefault();

		post(route('login'));
	};

	return (
		<GuestLayout>
			<Head title="Log in" />

			{status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

			{errors && errors.social && <div className="text-red-600">{errors.social}</div>}

			<form onSubmit={submit}>
				{errors.credentials && (
					<div className="alert alert-error text-xs my-4">{errors.credentials}</div>
				)}

				<div className="form-control w-full">
					<FormControl>
						<Label name="Email"></Label>
						<TextInput
							type="email"
							name="email"
							value={data.email}
							errors={errors.email}
							isFocused={true}
							onChange={onHandleChange}
						/>
						{errors.email && <div className="text-error text-xs">{errors.email}</div>}
					</FormControl>
				</div>

				<div className="form-control w-full">
					<FormControl>
						<Label name="Password"></Label>
						<TextInput
							type="password"
							name="password"
							value={data.password}
							errors={errors.password}
							onChange={onHandleChange}
						/>
						{errors.password && (
							<div className="text-error text-xs">{errors.password}</div>
						)}
					</FormControl>
				</div>

				<div className="form-control mt-4">
					<label className="flex items-center">
						<Checkbox
							name="remember"
							value={data.remember}
							handleChange={onHandleChange}
						/>
						<span className="ml-2 label-text">Remember me</span>
					</label>
				</div>

				<div className="flex items-center justify-end mt-4">
					{canResetPassword && (
						<Link
							href={route('password.request')}
							className="label-text-alt link link-hover"
						>
							Forgot your password?
						</Link>
					)}

					<PrimaryButton className="ml-4" processing={processing}>
						Log in
					</PrimaryButton>
				</div>

				<div className="mt-4">
					Don't have an account?{' '}
					<Link href={route('register')} className="link">
						Register here
					</Link>
				</div>

				<div className="divider">OR</div>

				<div className="flex flex-col gap-y-2 mt-4">
					<Link
						href={route('oauth.login', 'discord')}
						className="btn"
						style={{ backgroundColor: '#7289da' }}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 640 512"
							className="w-4 h-4 mr-2"
						>
							<path
								fill="currentColor"
								d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
							/>
						</svg>
						Log in with Discord
					</Link>

					<Link
						href={route('oauth.login', 'facebook')}
						className="btn"
						style={{ backgroundColor: '#3b5998' }}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512"
							className="w-4 h-4 mr-2"
						>
							<path
								fill="currentColor"
								d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
							/>
						</svg>
						Log in with Facebook
					</Link>
				</div>
			</form>
		</GuestLayout>
	);
}
